export const NAV_SIDEBAR_WIDTH = 270;
export const TOP_APPBAR_HEIGHT = 57;
export const NOTIFICATION_SIDEBAR_WIDTH = 40;

export const DateFormatEnum = {
  HYPHEN: 'yyyy-MM-dd',
  FORWARD_SLASH: 'yyyy/MM/dd'
};

export const CurrencyEnum = {
  USD: {
    code: 'USD',
    symbol: '\u0024'
  },
  US: {
    code: 'USD',
    symbol: '\u0024'
  },
  NGN: {
    code: 'NGN',
    symbol: '\u20A6'
  },
  NG: {
    code: 'NGN',
    symbol: '\u20A6'
  },
  GHS: {
    code: 'GHS',
    symbol: '\u20B5'
  },
  GH: {
    code: 'GHS',
    symbol: '\u20B5'
  },
  KES: {
    code: 'KES',
    symbol: 'KE'
  },
  KE: {
    code: 'KES',
    symbol: 'KE'
  },
  RWF: {
    code: 'RWF',
    symbol: 'RW'
  },
  RW: {
    code: 'RWF',
    symbol: 'RW'
  },
  TZS: {
    code: 'TZS',
    symbol: 'TZ'
  },
  TZ: {
    code: 'TZS',
    symbol: 'TZ'
  },
  UGX: {
    code: 'UGX',
    symbol: 'UG'
  },
  UG: {
    code: 'UGX',
    symbol: 'UG'
  },
  ZAR: {
    code: 'ZAR',
    symbol: 'ZA'
  },
  ZA: {
    code: 'ZAR',
    symbol: 'ZA'
  },
  ZMW: {
    code: 'ZMW',
    symbol: 'ZM'
  },
  ZM: {
    code: 'ZMW',
    symbol: 'ZM'
  }
};
