import styled from 'styled-components';
import { Collapse, Modal } from 'antd';

export const Container = styled.div``;

export const CustomCollapsePanel = styled(Collapse.Panel)`
  background-color: #fff;
  & > div {
    padding: 16px;
  }
`;

export const CardCover = styled.div`
  background-color: ${(props) => props.color};
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    border: 1px solid #cbd5e1;
  }

  .ant-modal-body {
    padding: 0;

    .top-wrapper {
      padding: 42px 32px 10px;
      text-align: center;
      margin: 0 auto;

      h3 {
        color: #344054;
        font-family: 'Figtree', sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 4px;
      }

      p {
        color: #667085;
        font-family: 'Figtree', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
      }
    }

    .bottom-wrapper {
      border-top: 1px solid #f0f0f0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 30px;

      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }

      button {
        border-radius: 8px;
        padding: 12px 20px;
        font-family: 'Figtree', sans-serif;
        font-size: 14px;
        font-weight: 500;
        min-height: 42px;
        line-height: 120%;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }
  }
`;
