import styled from 'styled-components';
import Card from 'common/Card';

export const Container = styled(Card)`
  height: 100%;
  overflow: auto;

  .payment-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;
