import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import moment from 'moment';

export interface SubscriptionState {
  due_date: string;
  plan: string;
  history: HistoryRes;
}

const initialState: SubscriptionState = {
  due_date: '',
  plan: '',
  history: []
};

export const fetchSubscription = createAsyncThunk<SubscriptionState, void>(
  'subscription',
  async (req, thunkApi) => {
    try {
      const patientId = localStorage.getItem('patientId');

      const firebaseDb = firebase.firestore();

      const subscriptionRef = await firebaseDb
        .collection('users')
        .where('patientId', '==', patientId)
        .get();

      const subscriptionInfo = subscriptionRef.docs[0].data()
        .subscription as SubscriptionState;

      if (
        !subscriptionInfo ||
        moment().isAfter(
          moment(subscriptionInfo.due_date, 'MMM Do, YYYY'),
          'day'
        )
      ) {
        // add new users & existing users with expired sub to the paygo plan
        thunkApi.dispatch(
          updateSubscription({
            plan: 'paygo',
            due_date: moment().add(1, 'year').format('MMM Do, YYYY'),
            description: 'paygo'
          })
        );
      }

      return subscriptionInfo;
    } catch (err: any) {
      return thunkApi.rejectWithValue({ error: err.message });
    }
  }
);

interface UpdateRes {
  due_date: string;
  plan: string;
}

interface UpdatePayload {
  due_date: string;
  plan: string;
  description?: string;
}

export const updateSubscription = createAsyncThunk<UpdateRes, UpdatePayload>(
  'updateSubscription',
  async (req, thunkApi) => {
    const patientId = localStorage.getItem('patientId')!;

    try {
      const firebaseDb = firebase.firestore();

      const docRef = await firebaseDb
        .collection('users')
        .where('patientId', '==', patientId);

      const docId = (await docRef.get()).docs[0].id;

      await firebaseDb
        .collection('users')
        .doc(docId)
        .update({
          subscription: {
            plan: req.plan,
            due_date: req.due_date
          }
        });

      // update patient's subscription history collection for non-paygo subs
      if (req.description !== 'paygo') {
        await firebaseDb
          .collection('subscriptionHistory')
          .doc(patientId)
          .collection('subscriptions')
          .add({
            createdAt: moment().format('MMM Do, YYYY'),
            description: req.description,
            plan: req.plan,
            amount: req.plan === 'basic' ? 20000 : 100000
          });
      }

      return {
        due_date: req.due_date,
        plan: req.plan
      };
    } catch (err: any) {
      console.error(err);

      return thunkApi.rejectWithValue({ error: err.message });
    }
  }
);

type HistoryRes = {
  createdAt: string;
  description: string;
  plan: string;
  amount: number;
}[];
export const fetchSubscriptionHistory = createAsyncThunk<HistoryRes, void>(
  'subscriptionHistory',
  async (req, thunkApi) => {
    const patientId = localStorage.getItem('patientId')!;
    try {
      const firebaseDb = firebase.firestore();
      const historyRef = await firebaseDb
        .collection('subscriptionHistory')
        .doc(patientId)
        .collection('subscriptions')
        .orderBy('createdAt', 'desc')
        .get();

      const historyDocs: HistoryRes = [];
      historyRef.docs.map((item) =>
        historyDocs.push(
          item.data() as {
            createdAt: string;
            description: string;
            amount: number;
            plan: string;
          }
        )
      );

      return historyDocs;
    } catch (err: any) {
      console.error(err);
      return thunkApi.rejectWithValue({ error: err.message });
    }
  }
);

export const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubscription.fulfilled, (state, action) => {
      state.due_date = action.payload.due_date;
      state.plan = action.payload.plan;
    });

    builder.addCase(updateSubscription.fulfilled, (state, action) => {
      state.due_date = action.payload.due_date;
      state.plan = action.payload.plan;
    });

    builder.addCase(fetchSubscriptionHistory.fulfilled, (state, action) => {
      state.history = action.payload;
    });
  }
});
