import { message } from 'antd';
import Swal from 'sweetalert2';
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

const appInstance = process.env.REACT_APP_ENVIRONMENT as string;

const http: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CONNECT_API_BASE_URL,
  headers: {
    Authorization: `Bearer ${
      appInstance === 'development'
        ? process.env.REACT_APP_PUBLIC_KEY //public key
        : process.env.REACT_APP_PRIVATE_KEY //private key
    }`
  }
});

http.defaults.headers.post['Content-Type'] = 'application/json';

http.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const {
      response,
      request
    }: {
      response?: AxiosResponse;
      request?: XMLHttpRequest;
    } = error;
    if (response) {
      if (
        response?.status === 401 &&
        response?.data?.error === 'Token expired'
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Session Expired!',
          text: 'Your session token has expired, please login again!'
        }).then(() => {
          logOutProvider();
        });
      }
      // return null;
    } else if (request) {
      const isOnline = window.navigator.onLine;

      message.error(
        isOnline
          ? 'Request failed. Please try again.'
          : 'Please Check your Network Connection, and try again.'
      );
      // return null;
    }
    return Promise.reject(error);
  }
);

const logOutProvider = () => {
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('_profile');
  window.location.replace('/');
};

export default http;
