import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Drawer, Grid } from 'antd';
import * as dfn from 'date-fns';

// svgs
import { ReactComponent as DashboardIcon } from 'assets/sidebar-health.svg';
import { ReactComponent as EncounterIcon } from 'assets/icons/encounters-icon3.svg';
import { ReactComponent as AppointmentIcon } from 'assets/icons/calendar-icon3.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile-icon.svg';
import { ReactComponent as SymptomsIcon } from 'assets/icons/symptoms-check-icon-1.svg';

import useSidebarState from 'hooks/useSidebarState';
import Typography from 'component/Typography';
import {
  SidebarDesktop,
  SidebarItemWrapper,
  SidebarListWrapper
} from './styles';
import useAuthUser from 'hooks/useAuthUser';
import { normalizeDateString } from 'utils/global';

export type SidebarItemProps = {
  route: string;
  title: string;
  icon: React.ElementType;
  adultFemaleOnly?: boolean;
};

const Sidebar = (props: any) => {
  const globalUser = useAuthUser() as any;

  const dob = normalizeDateString(globalUser?.dob);
  const patientAge = dfn.differenceInYears(new Date(), new Date(dob!));
  const sidebarMenu = [
    {
      title: 'My Health',
      route: '/my-health',
      icon: DashboardIcon
    },
    {
      title: 'My consultations',
      route: '/encounters',
      icon: EncounterIcon,
      adultFemaleOnly: globalUser?.gender !== 'female' || patientAge < 16
    },
    {
      title: 'Symptoms checker',
      route: '/symptoms',
      icon: SymptomsIcon
    },
    {
      title: 'Book appointments',
      route: '/services',
      icon: AppointmentIcon
    },
    {
      title: 'Profile',
      route: '/profile',
      icon: ProfileIcon
    }
  ];

  const { isSidebar, toggleSidebar } = useSidebarState();

  const breakpoints = Grid.useBreakpoint();

  const menu = (
    <SidebarListWrapper>
      <SidebarItemWrapper>
        {sidebarMenu.map((item) => (
          <SidebarItem key={item.route} {...item} />
        ))}
      </SidebarItemWrapper>
    </SidebarListWrapper>
  );

  if (breakpoints.lg) {
    return <SidebarDesktop>{menu}</SidebarDesktop>;
  }
  return (
    <Drawer
      bodyStyle={{
        padding: 0
      }}
      visible={isSidebar}
      onClose={toggleSidebar}
      placement="left"
    >
      {menu}
    </Drawer>
  );
};

export default Sidebar;

const SidebarItem = styled(function SidebarItem(props: SidebarItemProps) {
  const { title, route, icon: Icon, adultFemaleOnly, ...rest } = props;
  return (
    <NavLink to={route} {...rest}>
      <Icon fill="currentColor" height={30} width={30} />
      <Typography color="inherit">{title}</Typography>
      <div />
    </NavLink>
  );
})`
  position: relative;
  align-items: center;
  text-decoration: none;
  padding: 8px 32px;
  color: ${({ theme }) => theme.palette.gray['500']};
  height: 36px;
  margin: 16px 0px;
  display: ${({ adultFemaleOnly }) => (adultFemaleOnly ? 'none' : 'flex')};

  &:hover {
    color: ${({ theme }) => theme.palette.primary[500]};
  }

  &.active {
    color: ${({ theme }) => theme.palette.primary[500]};

    & div {
      display: block;
    }
  }

  div {
    display: none;
    position: absolute;
    right: 0px;
    width: 3px;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.primary[500]};
  }

  svg {
    margin-right: 16px;
  }
`;
