import { useAppSelector } from 'redux/store';

import Button from 'common/Button';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';

import { Wrapper } from './styles';

type UpgradePlanModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

function UpgradePlanModal({ isOpen, onClose }: UpgradePlanModalProps) {
  const { plan } = useAppSelector((state) => state.subscription);

  const basicPaymentURL =
    process.env.REACT_APP_ENVIRONMENT === 'development'
      ? process.env.REACT_APP_BASIC_PAYMENT_URL_TEST
      : process.env.REACT_APP_BASIC_PAYMENT_URL_PROD;

  const premiumPaymentURL =
    process.env.REACT_APP_ENVIRONMENT === 'development'
      ? process.env.REACT_APP_PREMIUM_PAYMENT_URL_TEST
      : process.env.REACT_APP_PREMIUM_PAYMENT_URL_PROD;

  return (
    <Wrapper
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      title={null}
      className="upgrade-modal"
    >
      <h2>Upgrade your plan</h2>
      <p>Get more out of your subscription by upgrading to higher plan</p>

      <div className="plans">
        <div className={`single-plan ${plan === 'paygo' ? 'active' : ''}`}>
          <div className="content">
            <h3>Pay as you go</h3>
            <ul>
              Pay for the care you need
              <li>
                <AddIcon />
                ₦3,000 for every consultation
              </li>
              <li>
                <AddIcon /> 30% discounts on all medications
              </li>
            </ul>
          </div>

          {plan === 'paygo' ? (
            <p className="action">Your current plan</p>
          ) : null}
        </div>

        <div className="single-plan">
          <div className="content">
            <h3>Basic Membership</h3>
            <h2>
              ₦20,000/<span>year</span>
            </h2>
            <ul>
              Full access to the North platform includes:
              <li>
                <AddIcon /> Unlimited consultations
              </li>
              <li>
                <AddIcon /> Next day appointments
              </li>
              <li>
                <AddIcon /> Free medications (first-fill only)
              </li>
              <li>
                <AddIcon /> Discounts on diagnostics
              </li>
              <li>
                <AddIcon /> Care navigation
              </li>
              <li>
                <AddIcon /> Care communities
              </li>
            </ul>
          </div>
          {plan === 'basic' ? (
            <p className="action">Your current plan</p>
          ) : plan === 'paygo' ? (
            <Button onClick={() => window.location.replace(basicPaymentURL!)}>
              Upgrade to plan
            </Button>
          ) : null}
        </div>

        <div className="single-plan">
          <div className="content">
            <h3>Premium Membership</h3>
            <h2>
              ₦60,000/<span>year</span>
            </h2>
            <ul>
              All of the care in Basic plus:
              <li>
                <AddIcon /> Access to 1800+ hospitals
              </li>
              <li>
                <AddIcon /> Dental & Eye care
              </li>
              <li>
                <AddIcon /> Health screenings
              </li>
              <li>
                <AddIcon /> Vaccinations and Immunizations
              </li>
              <li>
                <AddIcon /> Gynecology, Physiotherapy & Surgeries
              </li>
              <li>
                <AddIcon /> Family planning and IVF
              </li>
              <li>
                <AddIcon /> Accident and Emergencies
              </li>
              <li>and many more...</li>
            </ul>
          </div>

          {plan === 'premium' ? (
            <p className="action">Your current plan</p>
          ) : plan === 'paygo' || plan === 'basic' ? (
            <Button onClick={() => window.location.replace(premiumPaymentURL!)}>
              Upgrade to plan
            </Button>
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
}

export default UpgradePlanModal;
