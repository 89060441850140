import styled from 'styled-components';
import { TOP_APPBAR_HEIGHT } from 'constants/global';
import { deviceSizes } from 'constants/theme';

const NavbarWrapper = styled.div`
  width: 100%;
  height: ${TOP_APPBAR_HEIGHT}px;
  position: fixed;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 100;
  box-shadow: 0px 1px 5px rgba(0, 120, 226, 0.25);

  img {
    width: 100px;
    object-fit: contain;
  }

  .upgrade-btn {
    display: none;
    gap: 8px;
    align-items: center;
    margin-right: 32px;
    background: #d9efff;
    border-radius: 6px;
    border: none;
    font-family: 'Figtree', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.primary['500']};

    @media ${deviceSizes.tablet} {
      display: flex;
    }
  }

  .continue-consultation {
    display: none;

    @media (min-width: 1200px) {
      display: flex;
    }

    background: #f8fdff;
    border: 1px solid #6fcff6;
    border-radius: 8px;
    padding: 6px 14px;
    align-items: center;
    margin-right: 42px;
    justify-content: center;
    gap: 32px;

    p {
      margin: 0;
      font-family: 'Figtree';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #0c8cc0;
    }

    button {
      background: #0faff0;
      border: 1px solid #0faff0;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
  }
`;

const MenuLink = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Figtree', sans-serif;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-align: right;
  color: ${({ theme }) => theme.palette.gray['700']};
  cursor: pointer;

  img {
    margin-left: 15px;
  }
`;

export { NavbarWrapper, MenuLink };
