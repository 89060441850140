import styled from 'styled-components';
import { NAV_SIDEBAR_WIDTH } from 'constants/global';
import { deviceSizes } from 'constants/theme';

export const SidebarListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & {
    > p {
      margin-top: auto;
      padding: 0 0 1em 2em;
      font-size: 0.95em;
    }
  }
`;

const SidebarDesktop = styled.aside`
  height: calc(100vh - 57px);
  margin-top: 57px;
  width: ${NAV_SIDEBAR_WIDTH}px;
  background-color: #fff;
  z-index: 0;
  position: fixed;
  left: 0;
  top: 0;
  box-shadow: 1px 0px 5px rgba(0, 120, 226, 0.25);
`;

export const SidebarItemWrapper = styled.div`
  padding-top: 32px;

  .checklist-wrapper {
    display: none;

    @media ${deviceSizes.laptop} {
      display: flex;
      margin: 48px auto 0;
      align-items: center;
      justify-content: center;
    }
  }
`;

const SidebarMenuList = styled.ul`
  list-style-type: none;
  margin-top: 30px;
`;

export { SidebarDesktop, SidebarMenuList };
