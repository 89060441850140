import { useState, useMemo, useEffect } from 'react';
import firebase from 'firebase/app';
import { init } from 'emailjs-com';
import { useLocation } from 'react-router-dom';

// @ts-ignore
import Engage from '@engage_so/js';

import 'antd/dist/antd.css';
import './App.css';
import DashboardLayout from 'layouts/DashboardLayout';
import OnboardingLayout from 'layouts/OnboardingLayout';
import LoadingSpin from 'component/LoadingSpin';
import useLoadingSpin from 'hooks/useLoadingSpin';
import { useAppDispatch } from 'redux/store';
import useAuthUser from 'hooks/useAuthUser';
import { profileApi } from 'redux/queries/profile';
import { setUserAction } from 'redux/reducers/global';

// @ts-ignore
import NotificationSound from 'assets/sounds/notification-sound.mp3';

// initialize emailjs
init(process.env.EMAILJS_USER_ID as string);

function App() {
  // init engage so
  Engage.init(process.env.REACT_APP_ENGAGE_PUBLIC_KEY!);

  const { search, pathname } = useLocation();
  const { isLoadingSpin } = useLoadingSpin();
  const globalUser = useAuthUser();
  const [loading, setLoading] = useState(true);

  const [logoutPatientMutation] = profileApi.useLogoutPatientMutation();

  const dispatch = useAppDispatch();

  // URL params
  // format -> /encounters/consults/4y4xgvymt39nrms?fromNorth=true&patientId=30mwbbu9wk64f00
  const params = useMemo(() => new URLSearchParams(search), [search]);

  localStorage.setItem(
    'anonymousUserDetails',
    JSON.stringify({
      patientId: params.get('patientId'),
      existingUser: params.get('existingUser')
    })
  );
  localStorage.setItem('anonymousConsultId', pathname.split('/consults/')[1]);

  useEffect(() => {
    let unsubscribeMethod: firebase.Unsubscribe;

    async function handleAuth() {
      if (params.get('fromNorth')) {
        try {
          await firebase.auth().signInAnonymously();
        } catch (err) {}
      }

      unsubscribeMethod = firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          dispatch(
            setUserAction({
              auth: {
                uid: user?.uid,
                email: user?.email,
                emailVerified: user?.emailVerified,
                displayName: user?.displayName,
                phoneNumber: user?.phoneNumber,
                photoURL: user?.photoURL
              },
              patientId: params.get('patientId') ?? globalUser.patientId
            })
          );
        }
        setLoading(false);
      });
    }

    handleAuth();

    return () => {
      unsubscribeMethod();
    };
  }, [dispatch, params, globalUser.patientId, logoutPatientMutation]);

  if (loading) {
    return <LoadingSpin spinning={loading} />;
  }

  return (
    <>
      {globalUser?.auth && globalUser?.patientId ? (
        <DashboardLayout />
      ) : (
        <OnboardingLayout />
      )}
      <LoadingSpin spinning={isLoadingSpin} />
      <audio id="message-audio" src={NotificationSound} />
    </>
  );
}

export default App;
