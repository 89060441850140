import { deviceSizes } from 'constants/theme';
import styled from 'styled-components';

export const OnboardingWrapper = styled.main`
  min-height: 100vh;
  position: relative;

  .nav {
    padding: 30px 14px;
    background: #ffffff;

    @media ${deviceSizes.laptop} {
      padding: 35px 70px;
    }
  }
`;

export const MainWrapper = styled.section`
  width: 450px;
  max-width: 90%;
  margin: 62px auto;
`;
