import { configureStore, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer, { RootState } from './reducers';
import connectApi from 'redux/queries/connect-api';
import { profileApi } from './queries/profile';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [connectApi.reducerPath]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }),
    connectApi.middleware,
    resetRTKQCacheOnLogoutMiddleware(connectApi)
  ],
  devTools: process.env.NODE_ENV !== 'production'
});

export function resetRTKQCacheOnLogoutMiddleware(...apis: any[]) {
  return (store: any) => (next: any) => (action: any) => {
    const result = next(action);
    if (profileApi.endpoints.logoutPatient.matchFulfilled(action)) {
      for (const api of apis) {
        store.dispatch(api.util.resetApiState());
      }
    }
    return result;
  };
}

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
export const persistor = persistStore(store);
export default store;
