import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/app';

export interface WalletState {
  amount: number;
}

const initialState: WalletState = {
  amount: 0
};

export const fetchWallet = createAsyncThunk<WalletState, void>(
  'wallet',
  async (req, thunkApi) => {
    try {
      const patientId = localStorage.getItem('patientId');
      const firebaseDb = firebase.firestore();

      const walletRef = await firebaseDb
        .collection('users')
        .where('patientId', '==', patientId)
        .get();

      const walletInfo = walletRef.docs[0].data().wallet as WalletState;

      return walletInfo;
    } catch (err: any) {
      return thunkApi.rejectWithValue({ error: err.message });
    }
  }
);

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWallet.fulfilled, (state, action) => {
      if (action.payload) {
        state.amount = action.payload.amount;
      } else {
        state.amount = 0;
      }
    });
  }
});
