import styled from 'styled-components';
import Button from 'common/Button';
import { Col } from 'antd';

export const Container = styled.div`
  height: 100%;
  padding: 16px;
`;

export const PaneFabToggle = styled(Button)`
  position: fixed;
  bottom: calc(100% - 50%);
  right: 0px;
  border-radius: 20px 0px 0px 20px;
  border-right: none;
  box-shadow: '0px 8px 10px -5px rgba(25,118,210,0.2),0px 16px 24px 2px rgba(25,118,210,0.14),0px 6px 30px 5px rgba(25,118,210,0.12)';
`;

export const Pane = styled(Col)`
  height: 100%;
  overflow: auto;
`;

export const Content = styled(Col)`
  height: 100%;
  overflow: auto;
`;
