import { useAppDispatch, useAppSelector } from '../redux/store';
import { toggleSidebarAction } from '../redux/reducers/global';

function useSidebarState() {
  const dispatch = useAppDispatch();
  const isSidebar = useAppSelector((state) => state.global.isSidebar);

  const toggleSidebar = () => dispatch(toggleSidebarAction());

  return { isSidebar, toggleSidebar };
}

export default useSidebarState;
