import mixpanel from 'mixpanel-browser';

// init mixpanel
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string);

const env_check = process.env.REACT_APP_ENVIRONMENT === 'production';

const actions = {
  track: (event: string, props: { [eventProps: string]: string | number }) => {
    if (env_check) mixpanel.track(event, props);
  },
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  people: {
    set: (props: { [eventProps: string]: string | number }) => {
      if (env_check) mixpanel.people.set(props);
    }
  }
};

export const Mixpanel = actions;
