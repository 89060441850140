import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';
import http from '../../utils/api';
import { message } from 'antd';

export interface ConsultError {
  message: string;
}

export interface ConsultState {
  consult: any;
  consults: Array<any>;
  patientConsults: Array<any>;
  consultsMetrics: any;
  isConsultLoading: boolean;
  isConsultsLoading: boolean;
  isPatientConsultsLoading: boolean;
  isConsultsMetricsLoading: boolean;
  isUpdatedConsultSuccess: boolean;
  acceptConsultLoading: boolean;
  rejectConsultLoading: boolean;
  endConsultLoading: boolean;
  updateConsultLoading: boolean;
  consultError: ConsultError;
  consultsError: ConsultError;
  patientConsultsError: ConsultError;
  consultsMetricsError: ConsultError;
  acceptConsultError: ConsultError;
  rejectConsultError: ConsultError;
  endConsultError: ConsultError;
  updateConsultError: ConsultError;
}

export const initialState: ConsultState = {
  consult: null,
  consults: [],
  patientConsults: [],
  consultsMetrics: null,
  isConsultLoading: true,
  isConsultsLoading: true,
  isPatientConsultsLoading: true,
  isConsultsMetricsLoading: true,
  isUpdatedConsultSuccess: false,
  acceptConsultLoading: false,
  rejectConsultLoading: false,
  endConsultLoading: false,
  updateConsultLoading: false,
  consultError: { message: '' },
  consultsError: { message: '' },
  patientConsultsError: { message: '' },
  consultsMetricsError: { message: '' },
  acceptConsultError: { message: '' },
  rejectConsultError: { message: '' },
  endConsultError: { message: '' },
  updateConsultError: { message: '' }
};

export const consultsSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // FETCH SINGLE CONSULTS
    fetchConsultLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isConsultLoading = payload;
    },
    fetchConsultSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.consult = payload;
    },
    fetchConsultFailed: (state, { payload }: PayloadAction<ConsultError>) => {
      state.consultError = payload;
    },
    // FETCH ALL CONSULTS
    fetchConsultsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isConsultsLoading = payload;
    },
    fetchConsultsSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.consults = payload;
    },
    fetchConsultsFailed: (state, { payload }: PayloadAction<ConsultError>) => {
      state.consultsError = payload;
    },
    // FETCH ALL PROVIDERS CONSULTS
    fetchPatientConsultsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isPatientConsultsLoading = payload;
    },
    fetchPatientConsultsSuccess: (
      state,
      { payload }: PayloadAction<Array<any>>
    ) => {
      state.patientConsults = payload;
    },
    fetchPatientConsultsFailed: (
      state,
      { payload }: PayloadAction<ConsultError>
    ) => {
      state.patientConsultsError = payload;
    },
    // FETCH CONSULTS METRICS
    fetchConsultsMetricsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isConsultsMetricsLoading = payload;
    },
    fetchConsultsMetricsSuccess: (
      state,
      { payload }: PayloadAction<Array<any>>
    ) => {
      state.consultsMetrics = payload;
    },
    fetchConsultsMetricsFailed: (
      state,
      { payload }: PayloadAction<ConsultError>
    ) => {
      state.consultsMetricsError = payload;
    },
    // ACCEPT CONSULT
    acceptConsultLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.acceptConsultLoading = payload;
    },
    acceptConsultSuccess: (state, { payload }: PayloadAction<Boolean>) => {
      // state.consults = payload;
    },
    acceptConsultFailed: (state, { payload }: PayloadAction<ConsultError>) => {
      state.acceptConsultError = payload;
    },
    // REJECT CONSULT
    rejectConsultLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.rejectConsultLoading = payload;
    },
    rejectConsultSuccess: (state, { payload }: PayloadAction<Boolean>) => {
      // state.consults = payload;
    },
    rejectConsultFailed: (state, { payload }: PayloadAction<ConsultError>) => {
      state.rejectConsultError = payload;
    },
    // END CONSULT
    endConsultLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.endConsultLoading = payload;
    },
    endConsultSuccess: (state, { payload }: PayloadAction<Boolean>) => {
      // state.consults = payload;
    },
    endConsultFailed: (state, { payload }: PayloadAction<ConsultError>) => {
      state.endConsultError = payload;
    },
    // END CONSULT
    updateConsultLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.updateConsultLoading = payload;
      state.isUpdatedConsultSuccess = false;
    },
    updateConsultSuccess: (state, { payload }: PayloadAction<Boolean>) => {
      state.consult = payload;
      state.isUpdatedConsultSuccess = true;
    },
    updateConsultFailed: (state, { payload }: PayloadAction<ConsultError>) => {
      state.updateConsultError = payload;
      state.isUpdatedConsultSuccess = false;
    }
  }
});

export const {
  fetchConsultLoading,
  fetchConsultSuccess,
  fetchConsultFailed,
  fetchConsultsLoading,
  fetchConsultsSuccess,
  fetchConsultsFailed,
  fetchPatientConsultsLoading,
  fetchPatientConsultsSuccess,
  fetchPatientConsultsFailed,
  fetchConsultsMetricsLoading,
  fetchConsultsMetricsSuccess,
  fetchConsultsMetricsFailed,
  acceptConsultLoading,
  acceptConsultSuccess,
  acceptConsultFailed,
  rejectConsultLoading,
  rejectConsultSuccess,
  rejectConsultFailed,
  endConsultLoading,
  endConsultSuccess,
  endConsultFailed,
  updateConsultLoading,
  updateConsultSuccess,
  updateConsultFailed
} = consultsSlice.actions;
export const consultSelector = (state: { consults: ConsultState }) =>
  state.consults;
export default consultsSlice.reducer;

/** Actions */

export const fetchSingleConsult =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchConsultLoading(true));
    await http
      .get(`/consults/${id}`)
      .then((res) => {
        const consult = res?.data?.data;
        dispatch(fetchConsultSuccess(consult));
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || 'An error occurred'
        };
        dispatch(fetchConsultFailed(message));
      });
    dispatch(fetchConsultLoading(false));
  };

export const fetchAllConsults =
  (query: string): AppThunk =>
  async (dispatch) => {
    dispatch(fetchConsultsLoading(true));
    await http
      .get(`/consults${query}`)
      .then((res) => {
        const consults = res?.data?.data;
        dispatch(fetchConsultsSuccess(consults));
        // message.success("Consults Fetched Successfully");
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || 'An error occurred'
        };
        dispatch(fetchConsultsFailed(_message));
        message.error(err?.response?.data?.message || 'An error occurred');
      });
    dispatch(fetchConsultsLoading(false));
  };

export const fetchAllPatientConsults =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchPatientConsultsLoading(true));
    await http
      .get(`/consults?patient=${id}`)
      .then((res) => {
        const consults = res?.data?.data;
        dispatch(fetchPatientConsultsSuccess(consults));
        // message.success("Patient Consults Fetched Successfully");
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || 'An error occurred'
        };
        dispatch(fetchPatientConsultsFailed(_message));
        message.error(err?.response?.data?.message || 'An error occurred');
      });
    dispatch(fetchPatientConsultsLoading(false));
  };

export const fetchConsultsMetrics = (): AppThunk => async (dispatch) => {
  dispatch(fetchConsultsMetricsLoading(true));
  await http
    .get(`/consults/metrics`)
    .then((res) => {
      const consults = res?.data?.data;
      dispatch(fetchConsultsMetricsSuccess(consults));
    })
    .catch((err) => {
      const _message = {
        message: err?.response?.data?.message || 'An error occurred'
      };
      dispatch(fetchConsultsMetricsFailed(_message));
      message.error(err?.response?.data?.message || 'An error occurred');
    });
  dispatch(fetchConsultsMetricsLoading(false));
};

export const acceptConsult =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(acceptConsultLoading(true));
    await http
      .post(`/consults/${id}/accept`)
      .then((res) => {
        dispatch(acceptConsultSuccess(true));
        dispatch(fetchAllConsults(''));
        message.success('Consult Accepted Successfully');
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || 'An error occurred'
        };
        dispatch(acceptConsultFailed(_message));
        message.error(err?.response?.data?.message || 'An error occurred');
      });
    dispatch(acceptConsultLoading(false));
  };

export const rejectConsult =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(rejectConsultLoading(true));
    await http
      .post(`/consults/${id}/reject`)
      .then((res) => {
        dispatch(rejectConsultSuccess(true));
        dispatch(fetchAllConsults(''));
        message.success('Consult Rejected Successfully');
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || 'An error occurred'
        };
        dispatch(rejectConsultFailed(_message));
        message.error(err?.response?.data?.message || 'An error occurred');
      });
    dispatch(rejectConsultLoading(false));
  };

export const endConsult =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(endConsultLoading(true));
    await http
      .post(`/consults/${id}/end`)
      .then((res) => {
        dispatch(endConsultSuccess(true));
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || 'An error occurred'
        };
        dispatch(endConsultFailed(message));
      });
    dispatch(endConsultLoading(false));
  };

export const updateConsult =
  (id: any, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(updateConsultLoading(true));
    await http
      .patch(`/consults/${id}`, payload)
      .then((res) => {
        dispatch(updateConsultSuccess(res.data?.data));
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || 'An error occurred'
        };
        dispatch(updateConsultFailed(message));
      });
    dispatch(updateConsultLoading(false));
  };
