import styled from 'styled-components';
import Spin from './Spin';

const LoadingSpin = styled(Spin)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.snackbar};
  background-color: rgba(25, 118, 210, 0.2);
  display: ${({ spinning }) => (spinning ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;

export default LoadingSpin;
