import { axiosBaseQuery } from 'utils/redux-toolkit-query';
import http from 'utils/connect-http';
import { createApi } from '@reduxjs/toolkit/query/react';

const PROVIDER_BASE_URL = process.env.REACT_APP_PROVIDER_BASE_URL;

const connectApi = createApi({
  reducerPath: 'connectApi',
  keepUnusedDataFor: 60 * 3,
  baseQuery: axiosBaseQuery(undefined, http),
  endpoints: (builder) => ({
    unfurl: builder.query({
      queryFn: async ({ data: url }, __, ___, baseQuery) => {
        try {
          //https://rlp-proxy.herokuapp.com/v2?url=http://daily.co
          return await baseQuery({
            url: `https://rlp-proxy.herokuapp.com/v2`,
            params: { url }
          });
        } catch (error: object | any) {
          return {
            error: {
              data: '',
              message: '',
              status: ''
            }
          };
        }
      }
    }),
    getMedications: builder.query({
      query: () => ({ baseURL: PROVIDER_BASE_URL, url: '/medications' })
    }),
    getConditions: builder.query({
      query: () => ({ baseURL: PROVIDER_BASE_URL, url: '/diagnosis' })
    }),
    getInvestigations: builder.query({
      query: () => ({ baseURL: PROVIDER_BASE_URL, url: '/investigations' })
    })
  })
});

export default connectApi;
