import { RouteProps, Route } from 'react-router-dom';
import Suspense from '../Suspense';

export type RouteComposerRouteConfig = { meta?: any } & RouteProps;
export type SplitConfig = { route: RouteProps; meta: any };

export type RouteComposerProps<R extends RouteComposerRouteConfig[]> = {
  routes: R;
  wrapper?: (children: JSX.Element[]) => JSX.Element;
  render?: (
    item: SplitConfig,
    index: number,
    routes: RouteComposerRouteConfig[]
  ) => JSX.Element;
  filter?: (
    item: SplitConfig,
    index: number,
    routes: RouteComposerRouteConfig[]
  ) => boolean;
};

function RouteComposer<R extends RouteComposerRouteConfig[]>(
  props: RouteComposerProps<R>
) {
  const { routes, wrapper, render, filter } = props;
  return (
    <Suspense>
      {wrapper!(
        routes
          .filter(({ meta, ...route }, ...args) =>
            filter!({ meta, route }, ...args)
          )
          .map(({ meta, ...route }, ...args) =>
            render!({ meta, route }, ...args)
          )
      )}
    </Suspense>
  );
}

RouteComposer.defaultProps = {
  wrapper: (children: any) => children,
  filter: () => true,
  render: ({ route }: any, index: number) => <Route key={index} {...route} />
};

export default RouteComposer;
