import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';
import http from '../../utils/api';
import { fetchSingleConsult } from './consults';
import { message } from 'antd';

export interface SymptomError {
  message: string;
}

export interface SymptomState {
  symptom: any;
  symptoms: Array<any>;
  isSymptomsLoading: boolean;
  isAddConsultSymptomsLoading: boolean;
  symptomsError: SymptomError;
  addConsultSymptomsError: SymptomError;
}

export const initialState: SymptomState = {
  symptom: null,
  symptoms: [],
  isSymptomsLoading: false,
  isAddConsultSymptomsLoading: false,
  symptomsError: { message: '' },
  addConsultSymptomsError: { message: '' }
};

export const symptomsSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    fetchSymptomsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isSymptomsLoading = payload;
    },
    fetchSymptomsSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.symptoms = payload;
    },
    fetchSymptomsFailed: (state, { payload }: PayloadAction<SymptomError>) => {
      state.symptomsError = payload;
    },
    addConsultSymptomsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isAddConsultSymptomsLoading = payload;
    },
    addConsultSymptomsSuccess: (state, { payload }: PayloadAction<Boolean>) => {
      // state.isAddSymptomsLoading = payload;
    },
    addConsultSymptomsFailed: (
      state,
      { payload }: PayloadAction<SymptomError>
    ) => {
      state.addConsultSymptomsError = payload;
    }
  }
});

export const {
  fetchSymptomsLoading,
  fetchSymptomsSuccess,
  fetchSymptomsFailed,
  addConsultSymptomsLoading,
  addConsultSymptomsSuccess,
  addConsultSymptomsFailed
} = symptomsSlice.actions;
export const symptomSelector = (state: { symptoms: SymptomState }) =>
  state.symptoms;
export default symptomsSlice.reducer;

/** Actions */

export const fetchAllSymptoms = (): AppThunk => async (dispatch) => {
  dispatch(fetchSymptomsLoading(true));
  await http
    .get('/symptoms')
    .then((res) => {
      const symptoms = res?.data?.data;
      dispatch(fetchSymptomsSuccess(symptoms));
    })
    .catch((err) => {
      const message = { message: err?.response?.data?.message };
      dispatch(fetchSymptomsFailed(message));
    });
  dispatch(fetchSymptomsLoading(false));
};

export const addConsultSymptoms =
  (consultId: any, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(addConsultSymptomsLoading(true));
    await http
      .post(`/consults/${consultId}/symptoms`, payload)
      .then((res) => {
        const symptoms = res?.data?.data;
        dispatch(addConsultSymptomsSuccess(symptoms));
        message.success('Symptoms Added to Consult Successfully');
        dispatch(fetchSingleConsult(consultId));
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || 'An error occurred'
        };
        dispatch(addConsultSymptomsFailed(_message));
        message.error(err?.response?.data?.message || 'An error occurred');
      });
    dispatch(addConsultSymptomsLoading(false));
  };
