export const EncounterRTKQueryTagEnum = {
  CONSULT: 'CONSULT'
};

export const ProfileRTKQueryTagEnum = {
  PROFILE: 'PROFILE'
};

export const SubscriptionRTKQueryTagEnum = {
  SUBSCRIPTION: 'SUBSCRIPTION'
};

export const DeviceRTKQueryTagEnum = {
  AUTHORIZED_DEVICES: 'AUTHORIZED_DEVICES'
};

export const HealthRTKQueryTagEnum = {
  VITALS: 'HealthVitals'
};

export const LaboratoryRTKQueryTagEnum = {
  REQUEST: 'Request'
};

export const QuestionsRTKQueryTagEnum = {
  QUESTIONS: 'QUESTIONS'
};
