import { Modal } from 'antd';
import { deviceSizes } from 'constants/theme';
import styled from 'styled-components';

export const Wrapper = styled(Modal)`
  .ant-modal-content {
    background: ${({ theme }) => theme.palette.common.white};
    border: 1px solid ${({ theme }) => theme.palette.gray['300']};
    border-radius: 12px;
  }

  .ant-modal-body {
    padding: 30px 15px;
    max-height: 90vh;
    overflow-y: scroll;
    font-family: 'Figtree', sans-serif;

    @media ${deviceSizes.laptop} {
      padding: 50px 60px;
    }

    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 38px;
      color: #344054;
      margin-bottom: 4px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: ${({ theme }) => theme.palette.gray['500']};
      margin: 0;
    }
  }

  .plans {
    margin-top: 32px;
    display: flex;
    gap: 16px;
    flex-direction: column;

    @media ${deviceSizes.laptop} {
      flex-direction: row;
    }

    .single-plan {
      width: 100%;
      border-radius: 8px;
      padding: 23px 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid #cbd5e1;

      @media ${deviceSizes.laptop} {
        min-width: 270px;
        max-width: 320px;
      }

      &.active {
        border: 2px solid ${({ theme }) => theme.palette.primary['500']};
      }

      h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
        color: #040235;
        margin: 0 0 8px;
      }

      h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
        color: #040235;
        margin: 0;

        span {
          font-size: 20px;
        }
      }

      ul {
        padding: 0;
        margin: 12px 0 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #444350;

        li {
          list-style: none;
          display: flex;
          align-items: center;
          gap: 6px;

          &:first-of-type {
            margin-top: 12px;
          }

          &:not(:last-of-type) {
            margin-bottom: 8px;
          }

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }

      p.action {
        margin-top: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        /* margin-top: auto; */
        color: ${({ theme }) => theme.palette.primary['700']};
      }

      button {
        margin-top: 32px;
        padding: 12px 20px;
        gap: 8px;
        height: 44px;
        background: ${({ theme }) => theme.palette.primary['500']};
        border-radius: 8px;
        font-family: 'Figtree';
        font-weight: 500;
        font-size: 17.4512px;
        line-height: 19px;
        color: #ffffff;
        width: 100%;
      }
    }
  }
`;
