import { Card as AntdCard, CardProps as AntdCardProps } from 'antd';
import styled from 'styled-components';

export type CardProps = {} & AntdCardProps;

const Card = styled(function Card(props: CardProps) {
  return <AntdCard {...props} />;
})(({ theme }) => ({
  borderRadius: '5px',
  overflow: 'hidden',
  boxShadow:
    '0px 3px 1px -2px rgba(25,118,210,0.2),0px 2px 2px 0px rgba(25,118,210,0.14),0px 1px 5px 0px rgba(25,118,210,0.12)'
}));

export const CardMeta = AntdCard.Meta;

export default Card;
