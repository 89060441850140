import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { profileApi } from 'redux/queries/profile';

export type GlobalSlice = {
  isSidebar: boolean;
  isLoadingSpin: boolean;
  user: {
    id: string;
    patientId: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    auth: {
      uid: string;
      email: string;
      emailVerified: boolean;
      displayName: string;
      phoneNumber: string;
      photoURL: string;
    };
    medical_record: {
      allergies: any[];
      blood_group: string | null;
      drinking_habit: string | null;
      existing_medical_conditions: any[];
      family_medical_history: any[];
      genotype: string | null;
      height: null | string;
      medications: any[];
      smoking_habit: any[];
      surgeries: any[];
      transfusion_history: any[];
      vaccinations: any[];
      weight: string | null;
    };
    verified?: boolean;
    metadata: {
      wallet?: number;
    };
  };
  isLaboratory: boolean;
};

const initialState = {
  isSidebar: false,
  isLoadingSpin: false,
  user: {
    // patientId: 'rpqlkhev828627o'
  },
  isLaboratory: false
} as GlobalSlice;

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    toggleSidebarAction: (state, { payload }: PayloadAction<undefined>) => {
      state.isSidebar = payload || !state.isSidebar;
    },
    toggleLoadingSpinAction: (state, { payload }) => {
      state.isLoadingSpin =
        payload !== undefined ? !!payload : !state.isLoadingSpin;
    },
    toggleLaboratoryAction: (state, { payload }: PayloadAction<undefined>) => {
      state.isLaboratory = payload || !state.isLaboratory;
    },
    setUser: (state, { payload }) => {
      Object.assign(state.user, payload);
    },
    logout: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        profileApi.endpoints.getPatientProfile.matchFulfilled,
        (state, { payload }) => {
          Object.assign(state.user, payload?.data);
        }
      )
      .addMatcher(
        profileApi.endpoints.createPatientProfile.matchFulfilled,
        (state, { payload }) => {
          Object.assign(state.user, payload?.data);
        }
      )
      .addMatcher(
        profileApi.endpoints.loginPatient.matchFulfilled,
        (state, { payload }) => {
          state.user.patientId = payload?.patientId;
        }
      )
      .addMatcher(
        profileApi.endpoints.logoutPatient.matchFulfilled,
        (state) => {
          Object.assign(state, initialState);
        }
      )
});

export const {
  toggleSidebarAction,
  toggleLoadingSpinAction,
  toggleLaboratoryAction,
  setUser: setUserAction
  // logout: logoutAction
} = globalSlice.actions;

export default globalSlice.reducer;
