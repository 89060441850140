export const RouteEnum = Object.freeze({
  HOMEPAGE: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  EMAIL_VERIFICATION: '/email-verification',
  TERMS_AND_CONDITIONS: '/terms-conditions',
  CREATE_PASSWORD: '/password/create',
  RESET_PASSWORD: '/password/reset',
  REQUEST_OTP: '/otp/request',
  ENTER_OTP: '/otp/enter',
  COMPLETE_SETUP: '/complete-setup',
  PROFILE: '/profile',
  PROFILE_EDIT: '/profile/edit',
  MY_HEALTH: '/my-health',
  ENCOUNTERS: '/encounters',
  ENCOUNTER_CONSULTS: '/encounters/consults',
  ENCOUNTERS_CONSULTS_DETAILS: '/encounters/consults/:id',
  ENCOUNTER_START: '/encounters/start',
  ENCOUNTER_START_QUESTIONS: '/encounters/start/:id',
  ENCOUNTER_SERVICES: '/encounters/services',
  ENCOUNTER_CARETEAM: '/encounters/care-team',
  PATIENT_ENCOUNTERS: '/encounters/:patientId',
  TREATMENTS: '/treatments',
  MY_DEVICES: '/my-devices',
  SERVICES: '/services',
  SERVICES_PROVIDERS_DETAIL: '/services/providers/:id',
  SYMPTOMS: '/symptoms',
  SINGLE_SYMPTOM: '/symptoms/:id'
});
