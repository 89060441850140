import { lazy, useEffect, useLayoutEffect } from 'react';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';

import RouteComposer, {
  RouteComposerRouteConfig
} from 'component/RouteComposer';
import { RouteEnum } from 'constants/routes';
import useAuthUser from 'hooks/useAuthUser';

import AppIcon from 'assets/icons/north-logo-lg.svg';
import { OnboardingWrapper, MainWrapper } from './styles';
import { Mixpanel } from 'utils/mixpanel';

const OnboardingLayout = () => {
  const location = useLocation();
  const history = useHistory();
  const user = useAuthUser();

  useEffect(() => {
    Mixpanel.track('page_view', {
      pageName: location.pathname.slice(1)
    });
  }, [location.pathname]);

  useLayoutEffect(() => {
    if (!user?.auth) {
      history.replace(RouteEnum.LOGIN);
    }
  }, [history, user?.auth]);

  return (
    <OnboardingWrapper>
      <div className="nav">
        <img
          src={AppIcon}
          alt="icon"
          style={{ cursor: 'pointer' }}
          onClick={() => (window.location.href = 'https://usenorth.care')}
        />
      </div>

      <MainWrapper>
        <RouteComposer
          routes={ROUTES}
          wrapper={(children) => (
            <Switch>
              {children}
              <Redirect to={RouteEnum.LOGIN} />
            </Switch>
          )}
        />
      </MainWrapper>
    </OnboardingWrapper>
  );
};

export default OnboardingLayout;

const ROUTES = [
  {
    exact: true,
    path: [RouteEnum.HOMEPAGE, RouteEnum.LOGIN],
    component: lazy(() => import('screens/Onboarding/Login'))
  },
  {
    path: RouteEnum.REGISTER,
    component: lazy(() => import('screens/Onboarding/Register'))
  },
  {
    path: RouteEnum.EMAIL_VERIFICATION,
    component: lazy(() => import('screens/Onboarding/Register/EmailLinkSent'))
  },
  {
    path: RouteEnum.TERMS_AND_CONDITIONS,
    component: lazy(() => import('screens/Onboarding/TermsAndConditions'))
  },
  {
    path: RouteEnum.CREATE_PASSWORD,
    component: lazy(() => import('screens/Onboarding/CreatePassword'))
  },
  {
    path: RouteEnum.RESET_PASSWORD,
    component: lazy(() => import('screens/Onboarding/RecoverPassword'))
  },
  {
    path: RouteEnum.REQUEST_OTP,
    component: lazy(() => import('screens/Onboarding/RequestOTP'))
  },
  {
    path: RouteEnum.ENTER_OTP,
    component: lazy(() => import('screens/Onboarding/EnterOTP'))
  },
  {
    path: RouteEnum.COMPLETE_SETUP,
    component: lazy(() => import('screens/Onboarding/CompleteSetup'))
  }
] as RouteComposerRouteConfig[];
