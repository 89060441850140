import { forwardRef } from 'react';
import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd';
import styled from 'styled-components';

export type ButtonProps = AntdButtonProps & {
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
};

const Button = styled(
  forwardRef<HTMLElement, ButtonProps>(function Button(props, ref) {
    return <AntdButton ref={ref} {...props} />;
  })
)(({ theme, type, color, shape }) => ({
  ...theme.typography.button,
  backgroundColor:
    type === 'primary' ? theme.palette.primary['500'] : undefined,
  color:
    type === 'primary' ? theme.palette.common.white : theme.palette.gray['500'],
  borderColor:
    type === 'dashed' || type === 'default' || type === 'primary'
      ? theme.palette.primary['500']
      : undefined,
  ...(shape !== 'circle' ? { borderRadius: '4px' } : {}),
  '&:hover': {
    backgroundColor:
      type === 'primary' ? theme.palette.primary['600'] : undefined,
    borderColor:
      type === 'dashed' || type === 'default' || type === 'primary'
        ? theme.palette.primary['600']
        : undefined,
    '&:disabled': {
      backgroundColor:
        type === 'primary' ? theme.palette.primary['200'] : undefined,
      color: type === 'primary' ? theme.palette.common.white : undefined
    }
  },
  '&:active, &:focus': {
    backgroundColor:
      type === 'primary' ? theme.palette.primary['500'] : undefined,
    borderColor:
      type === 'dashed' || type === 'default' || type === 'primary'
        ? theme.palette.primary['500']
        : undefined
  },
  '&:disabled': {
    color: type === 'primary' ? theme.palette.common.white : undefined,
    backgroundColor:
      type === 'primary' ? theme.palette.primary['200'] : undefined,
    borderColor:
      type === 'dashed' || type === 'default' || type === 'primary'
        ? theme.palette.primary['200']
        : undefined
  }
}));

Button.defaultProps = {
  type: 'default',
  color: 'primary'
};

export default Button;
