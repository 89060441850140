import styled from 'styled-components';

interface LoaderProps {
  height: number;
}

const LoaderWrapper = styled.div<LoaderProps>`
  display: flex;
  height: ${(props) => props.height || 300}px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export { LoaderWrapper };
