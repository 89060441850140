import { combineReducers } from '@reduxjs/toolkit';
import global from './global';
import connectApi from 'redux/queries/connect-api';
import AuthReducer from './auth';
import ExaminationReducer from './examinations';
import EncountersReducer from './encounters';
import SymptomReducer from './symptoms';
import DiagnosisReducer from './diagnoses';
import ConsultReducer from './consults';
import MessageReducer from './messages';
import InvestigationReducer from './investigations';
import MedicationReducer from './medications';
import ProfileReducer from './profile';
import { subscriptionSlice } from '../slices/subscriptions';
import { walletSlice } from '../slices/wallet';

const rootReducer = combineReducers({
  global,
  [connectApi.reducerPath]: connectApi.reducer,
  auth: AuthReducer,
  encounters: EncountersReducer,
  examinations: ExaminationReducer,
  symptoms: SymptomReducer,
  medications: MedicationReducer,
  investigations: InvestigationReducer,
  diagnoses: DiagnosisReducer,
  consults: ConsultReducer,
  messages: MessageReducer,
  profile: ProfileReducer,
  subscription: subscriptionSlice.reducer,
  wallet: walletSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
