import { lazy, useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';

import RouteComposer, {
  RouteComposerRouteConfig
} from 'component/RouteComposer';
import { RouteEnum } from 'constants/routes';
import Navbar from 'component/Navbar';
import Sidebar from 'component/Sidebar';
import ContentLoading from 'component/ContentLoading';
import { MainWrapper } from './styles';

import { profileApi } from 'redux/queries/profile';
import { Mixpanel } from 'utils/mixpanel';

const DashboardLayout = (props: any) => {
  const location = useLocation();
  const { data, isLoading, isError, refetch } =
    profileApi.useGetPatientProfileQuery(undefined);

  //Mixpanel identification
  useEffect(() => {
    if (data) {
      Mixpanel.identify(data.data.patientId);
    }
  }, [data]);

  useEffect(() => {
    Mixpanel.track('page_view', {
      pageName: location.pathname.slice(1)
    });
  }, [location.pathname]);

  return (
    <ContentLoading loading={isLoading} error={isError} onReload={refetch}>
      {() => (
        <>
          <Navbar />
          <Sidebar />
          <MainWrapper>
            <RouteComposer
              routes={ROUTES}
              wrapper={(children) => (
                <Switch>
                  {children}
                  <Redirect to={RouteEnum.MY_HEALTH} />
                </Switch>
              )}
            />
          </MainWrapper>
        </>
      )}
    </ContentLoading>
  );
};

export default DashboardLayout;

const ROUTES = [
  {
    path: RouteEnum.MY_HEALTH,
    component: lazy(() => import('screens/my-health/MyHealth'))
  },
  {
    path: RouteEnum.ENCOUNTERS,
    component: lazy(() => import('screens/Encounters'))
  },
  {
    path: RouteEnum.SERVICES,
    component: lazy(() => import('screens/Services'))
  },
  {
    path: RouteEnum.PROFILE,
    component: lazy(() => import('screens/Profile'))
  },
  {
    path: RouteEnum.MY_DEVICES,
    component: lazy(() => import('screens/my-device/MyDevice'))
  },
  {
    path: RouteEnum.SYMPTOMS,
    component: lazy(() => import('screens/Symptoms'))
  }
] as RouteComposerRouteConfig[];
