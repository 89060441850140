import styled from 'styled-components';
import { Radio } from 'antd';

import { deviceSizes } from 'constants/theme';
import { NAV_SIDEBAR_WIDTH, TOP_APPBAR_HEIGHT } from 'constants/global';

const DashboardWrapper = styled.div`
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  z-index: 0;
`;

const MainWrapper = styled.section`
  height: calc(100% - ${TOP_APPBAR_HEIGHT}px);
  transform: translateY(${TOP_APPBAR_HEIGHT}px);
  overflow: auto;

  @media ${deviceSizes.laptop} {
    margin-left: ${NAV_SIDEBAR_WIDTH}px;
  }
`;

export const PlansWrapper = styled.main`
  display: flex;
  min-height: 100vh;

  > div {
    flex: 1;
  }

  .left {
    padding: 40px 20px;

    @media ${deviceSizes.laptop} {
      padding: 40px;
    }

    @media ${deviceSizes.desktop} {
      padding: 40px 100px;
    }

    img {
      margin-bottom: 70px;

      @media ${deviceSizes.laptop} {
        margin-bottom: 85px;
      }
    }

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: ${({ theme }) => theme.palette.gray['900']};
      margin-bottom: 8px;

      @media ${deviceSizes.laptop} {
        font-size: 36px;
        line-height: 46px;
      }
    }

    > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: ${({ theme }) => theme.palette.gray['500']};

      @media ${deviceSizes.laptop} {
        font-size: 18px;
      }
    }

    button {
      width: 100%;
      height: 48px;
      font-size: 16px;

      @media ${deviceSizes.laptop} {
        max-width: 450px;
      }

      @media ${deviceSizes.desktop} {
        max-width: 650px;
      }
    }
  }

  .right {
    display: none;
    position: relative;

    @media ${deviceSizes.laptop} {
      display: grid;
      place-content: center;
      padding: 135px 84px;
      background: #cfeffc;
    }

    img:first-of-type {
      width: 350px;
      height: 400px;
      object-fit: contain;

      @media ${deviceSizes.desktop} {
        width: 550px;
        height: 570px;
      }
    }

    img:nth-of-type(2) {
      width: 260px;
      height: 98px;
      position: absolute;
      bottom: 140px;
      left: 35%;
    }
  }
`;

export const RadioGroup = styled(Radio.Group)`
  margin: 32px 0;
  display: flex;
  gap: 15px;
  flex-direction: column;

  @media ${deviceSizes.laptop} {
    max-width: 450px;
  }

  @media ${deviceSizes.desktop} {
    max-width: 650px;
  }

  label {
    border: 1px solid ${({ theme }) => theme.palette.gray['300']};
    border-radius: 8px;
    padding: 22px 12px;
    display: flex;
    align-items: flex-start;

    > span:nth-of-type(2) {
      width: 100%;

      > div {
        p:first-of-type {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;

          span:first-of-type {
            font-weight: 500;
            font-size: 14px;
            line-height: 120%;
            color: ${({ theme }) => theme.palette.gray['700']};

            @media ${deviceSizes.laptop} {
              font-size: 18px;
            }
          }

          span:nth-of-type(2) {
            font-weight: 700;
            font-size: 18px;
            line-height: 120%;
            color: ${({ theme }) => theme.palette.gray['900']};

            @media ${deviceSizes.laptop} {
              font-size: 25px;
            }
          }
        }

        p:nth-of-type(2) {
          font-weight: 400;
          font-size: 14px;
          line-height: 125%;
          color: ${({ theme }) => theme.palette.gray['500']};

          @media ${deviceSizes.laptop} {
            font-size: 16px;
          }
        }
      }
    }

    &:hover {
      border-color: ${({ theme }) => theme.palette.primary['500']};
      transition: all 0.3s ease;
    }

    &.ant-radio-wrapper-checked {
      border-color: ${({ theme }) => theme.palette.primary['500']};
    }
  }
`;

export { DashboardWrapper, Container, MainWrapper };
