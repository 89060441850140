import connectApi from './connect-api';
import {
  EncounterRTKQueryTagEnum,
  SubscriptionRTKQueryTagEnum
} from 'constants/redux';
import { invalidatesList, providesList } from 'utils/redux-toolkit-query';
import { Mixpanel } from 'utils/mixpanel';
import { triggerPushNotification } from 'utils/helpers';
import pusher from 'utils/pusher';

export const encounterStartApi = connectApi.injectEndpoints({
  endpoints: (builder) => ({
    getEncounterComplaintForm: builder.query({
      query: ({ path }) => ({ url: `/telehealth/forms/${path.id}` })
    }),
    sumbitEncounterComplaintForm: builder.mutation({
      query: ({ data }) => ({
        url: `/telehealth/forms/answer`,
        method: 'POST',
        data
      })
    })
  })
});

export const encounterConsultApi = connectApi.injectEndpoints({
  endpoints: (builder) => ({
    getEncounterConsults: builder.query({
      query: ({ userId }) => ({
        url: `/consults/user/${userId}` // Id should be dynamic
      }),
      providesTags: (data) =>
        providesList(EncounterRTKQueryTagEnum.CONSULT, data?.data) as any
    }),
    createEncounterConsult: builder.mutation({
      query: ({ data }) => ({ url: `/consults`, method: 'POST', data }),
      invalidatesTags: [
        { type: EncounterRTKQueryTagEnum.CONSULT as never },
        { type: SubscriptionRTKQueryTagEnum.SUBSCRIPTION as never }
      ]
    }),
    updateEncounterConsult: builder.mutation<
      void,
      { id: string; data: { [key: string]: string | object } }
    >({
      query: ({ id, data }) => ({
        url: `/consults/${id}`,
        method: 'PUT',
        data
      }),
      invalidatesTags: invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
    }),
    getEncounterConsultMessages: builder.query({
      query: ({ path }) => ({ url: `/consults/${path.consultId}/messages` }),
      async onCacheEntryAdded(
        { path },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) {
        function playSound() {
          const audioElement = document.getElementById(
            'message-audio'
          ) as HTMLAudioElement;

          audioElement.play();
        }

        try {
          await cacheDataLoaded;

          const channel = pusher.subscribe(path.consultId);

          channel.bind('messages', (data: any) => {
            if (data.sender.type === 'provider') {
              playSound();

              Mixpanel.track('message_received', { encounterId: data.id });

              triggerPushNotification(
                'New Message',
                `You have a new consultation message from Dr. ${data.sender?.first_name}.`
              )
                .then(console.log)
                .catch(console.error);
            }

            if (data?.sender_model) {
              data.sender.type = data?.sender_model;
            }

            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );

            updateCachedData((draft) => {
              draft.data.push(data);
            });
          });
        } catch (error: object | any) {}

        await cacheEntryRemoved;
      }
    }),
    onEncounterConsultStatusChange: builder.query({
      queryFn: () => ({ data: '' }),
      onCacheEntryAdded: async (
        { path },
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) => {
        try {
          await cacheDataLoaded;
          const channel = pusher.subscribe(`consult-${path.consultId}`);

          channel.bind('consult_accepted', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );

            triggerPushNotification(
              'Consultation',
              `Your consult request: ${data?.form?.name} has been accepted.`
            )
              .then(console.log)
              .catch(console.error);

            Mixpanel.track('encounter_accepted', {
              encounterId: data?.id,
              formName: data?.form.name,
              formId: data?.form.id
            });
          });

          channel.bind('consult_rejected', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );

            triggerPushNotification(
              'Consultation',
              `Your consult request: ${data?.form?.name} has been rejected.`
            )
              .then(console.log)
              .catch(console.error);

            Mixpanel.track('encounter_rejected', {
              encounterId: data?.id,
              formName: data?.form.name,
              formId: data?.form.id
            });
          });

          channel.bind('consult_completed', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );

            triggerPushNotification(
              'Consultation',
              `Your consult: ${data?.form?.name} has been completed.`
            )
              .then(console.log)
              .catch(console.error);
            Mixpanel.track('encounter_completed', {
              encounterId: data?.id,
              formName: data?.form.name,
              formId: data?.form.id
            });
          });
        } catch (error: object | any) {}

        await cacheEntryRemoved;
      }
    }),
    sendEncounterConsultMessage: builder.mutation({
      query: ({ path, data }) => ({
        url: `/consults/${path.consultId}/messages`,
        method: 'POST',
        data
      })
    }),
    markEncounterConsultComplete: builder.mutation({
      query: ({ path }) => ({
        url: `/consults/${path.consult_id}/treatment_completed`,
        method: 'PATCH'
      }),
      invalidatesTags: invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
    }),
    rateConsult: builder.mutation({
      query: ({ path, data }) => ({
        url: `/consults/${path.consult_id}/ratings`,
        data,
        method: 'POST'
      }),
      invalidatesTags: invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
    }),
    onPrescriptionAddition: builder.query({
      queryFn: () => ({ data: '' }),
      onCacheEntryAdded: async (
        { path },
        { cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) => {
        try {
          await cacheDataLoaded;

          //setup pusher subscription and bind pusher events accordingly
          const channel = pusher.subscribe(`consult-${path.consultId}`);

          channel.bind('consult.medications.created', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );

            triggerPushNotification(
              'Medications',
              `New suggested medication: ${data[0].name}`
            )
              .then(console.log)
              .catch(console.error);
          });
          channel.bind('consult.medications.deleted', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );

            triggerPushNotification('Medications', `Medication removed`)
              .then(console.log)
              .catch(console.error);
          });
        } catch (error: object | any) {}

        await cacheEntryRemoved;
      }
    }),
    onLabInvestigationAddition: builder.query({
      queryFn: () => ({ data: '' }),
      onCacheEntryAdded: async (
        { path },
        { cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) => {
        try {
          await cacheDataLoaded;

          //setup pusher subscription and bind pusher events accordingly
          const channel = pusher.subscribe(`consult-${path.consultId}`);

          channel.bind('consult.investigation.created', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );

            triggerPushNotification(
              'Lab Investigation',
              `New suggested investigation: ${data[data.length - 1].name}`
            )
              .then(console.log)
              .catch(console.error);
          });

          channel.bind('consult.investigation.deleted', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );

            triggerPushNotification(
              'Lab Investigation',
              `Investigation removed`
            )
              .then(console.log)
              .catch(console.error);
          });
        } catch (error: object | any) {}

        await cacheEntryRemoved;
      }
    }),
    onRecommendationAddition: builder.query({
      queryFn: () => ({ data: '' }),
      onCacheEntryAdded: async (
        { path },
        { cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) => {
        try {
          await cacheDataLoaded;

          //setup pusher subscription and bind pusher events accordingly
          const channel = pusher.subscribe(`consult-${path.consultId}`);

          channel.bind('consult.othertreament.created', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );

            triggerPushNotification(
              'Recommendations',
              `New recommendation: ${data[data.length - 1].plan}`
            )
              .then(console.log)
              .catch(console.error);
          });

          channel.bind('consult.othertreament.deleted', (data: any) => {
            dispatch(
              encounterConsultApi.util.invalidateTags(
                invalidatesList(EncounterRTKQueryTagEnum.CONSULT) as any
              )
            );

            triggerPushNotification('Recommendations', 'Recommendation removed')
              .then(console.log)
              .catch(console.error);
          });
        } catch (error: object | any) {}

        await cacheEntryRemoved;
      }
    })
  })
});

encounterConsultApi.enhanceEndpoints({
  addTagTypes: [
    ...Object.values(EncounterRTKQueryTagEnum),
    ...Object.values(SubscriptionRTKQueryTagEnum)
  ]
});
