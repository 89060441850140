import _http from 'utils/api';
import { AxiosInstance, AxiosRequestConfig } from 'axios';

export function axiosBaseQuery(
  baseConfig?: AxiosRequestConfig,
  http: AxiosInstance = _http
) {
  return request;

  async function request(config?: AxiosRequestConfig) {
    try {
      const response = await http.request(config!);
      return {
        ...response,
        meta: { request: response.request, response }
      };
    } catch (error: object | any) {
      return {
        error: error.response        
          ? {
              message: error.response.data.message,
              status: error.response.status,
              data: error.response.data
            }
          : {
              message: 'Something went wrong',
              data: { message: 'Something went wrong' }
            }
      };
    }
  }
}

export function providesList<R extends any[]>(
  tagType: string,
  resultsWithIds: R,
  options = {} as { selectId: (id: R[0]) => string }
) {
  const { selectId = ({ id }) => id } = options;
  const listTag = { type: tagType, id: 'LIST' };
  const result = resultsWithIds
    ? [
        listTag,
        ...resultsWithIds.map((result) => ({
          type: tagType,
          id: selectId(result)
        }))
      ]
    : [listTag];

  return result;
}

export function invalidatesList(
  tagType: string,
  options = {} as { ids: string[] }
) {
  const { ids = [] } = options;
  const result = [
    { type: tagType, id: 'LIST' },
    ...ids.map((id) => ({ type: tagType, id }))
  ];

  return result;
}
