const THEME = {
  palette: {
    common: {
      black: '#000000',
      white: '#FFFFFF'
    },

    primary: {
      '50': '#CFEFFC',
      '100': '#B7E7FA',
      '200': '#9FDFF9',
      '300': '#6FCFF6',
      '400': '#3FBFF3',
      '500': '#0FAFF0',
      '600': '#0C8CC0',
      '700': '#096990',
      '800': '#064660',
      '900': '#053548'
    },

    gray: {
      '50': '#F8FAFC',
      '100': '#F1F5F9',
      '200': '#E2E8F0',
      '300': '#CBD5E1',
      '400': '#94A3B8',
      '500': '#64748B',
      '600': '#475569',
      '700': '#334155',
      '800': '#1E293B',
      '900': '#0F172A'
    },

    error: {
      '50': '#FFF1F2',
      '100': '#FFF1F2',
      '200': '#FECDD3',
      '300': '#FDA4AF',
      '400': '#FB7185',
      '500': '#F43F5E',
      '600': '#E11D48',
      '700': '#BE123C',
      '800': '#9F1239',
      '900': '#881337'
    },

    warning: {
      '50': '#FFFBEB',
      '100': '#FEF3C7',
      '200': '#FDE68A',
      '300': '#FCD34D',
      '400': '#FBBF24',
      '500': '#F59E0B',
      '600': '#D97706',
      '700': '#B45309',
      '800': '#92400E',
      '900': '#78350F'
    },

    success: {
      '50': '#ECFDF5',
      '100': '#D1FAE5',
      '200': '#A7F3D0',
      '300': '#6EE7B7',
      '400': '#34D399',
      '500': '#10B981',
      '600': '#059669',
      '700': '#047857',
      '800': '#065F46',
      '900': '#064E3B'
    }
  },

  typography: {
    htmlFontSize: 16,
    fontFamily: "'Figtree', 'sans-serif'",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,

    h1: {
      fontFamily: "'Figtree', 'sans-serif'",
      fontWeight: 300,
      fontSize: '6rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em'
    },

    h2: {
      fontFamily: "'Figtree', 'sans-serif'",
      fontWeight: 300,
      fontSize: '3.75rem',
      lineHeight: 1.2,
      letterSpacing: '-0.00833em'
    },

    h3: {
      fontFamily: "'Figtree', 'sans-serif'",
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.167,
      letterSpacing: '0em'
    },

    h4: {
      fontFamily: "'Figtree', 'sans-serif'",
      fontWeight: 400,
      fontSize: '2.125rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em'
    },

    h5: {
      fontFamily: "'Figtree', 'sans-serif'",
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: '0em'
    },

    h6: {
      fontFamily: "'Figtree', 'sans-serif'",
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em'
    },

    subtitle1: {
      fontFamily: "'Figtree', 'sans-serif'",
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em'
    },

    subtitle2: {
      fontFamily: "'Figtree', 'sans-serif'",
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em'
    },

    body1: {
      fontFamily: "'Figtree', 'sans-serif'",
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em'
    },

    body2: {
      fontFamily: "'Figtree', 'sans-serif'",
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em'
    },

    button: {
      fontFamily: "'Figtree', 'sans-serif'",
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em'
    },

    caption: {
      fontFamily: "'Figtree', 'sans-serif'",
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em'
    },

    overline: {
      fontFamily: "'Figtree', 'sans-serif'",
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase'
    }
  },

  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500
  }
};

export default THEME;

export const deviceSizes = {
  mobile: `(min-width: 425px)`,
  tablet: `(min-width: 768px)`,
  laptop: `(min-width: 1024px)`,
  desktop: `(min-width: 1440px)`
};
