import { Link, useLocation, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Dropdown, Menu, Space, Grid } from 'antd';

import Button from 'common/Button';
import useSidebarState from 'hooks/useSidebarState';
import useAuthUser from 'hooks/useAuthUser';
import UserAvatar from 'component/UserAvatar';
import { NavbarWrapper, MenuLink } from './styles';

import { profileApi } from 'redux/queries/profile';
import { fetchSubscription } from 'redux/slices/subscriptions';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { encounterConsultApi } from 'redux/queries/encounter';

import AppIcon from 'assets/icons/north-logo-lg.svg';
import { ReactComponent as NavbarMenuSvg } from 'assets/navbar-menu.svg';
import { ReactComponent as UpgradeIcon } from 'assets/icons/upgrade-icon.svg';
import UpgradePlanModal from 'component/UpgradePlanModal';
import { Mixpanel } from 'utils/mixpanel';

const Navbar = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { plan } = useAppSelector((state) => state.subscription);
  const user = useAuthUser() as any;
  const { toggleSidebar } = useSidebarState();
  const breakpoints = Grid.useBreakpoint();

  const { id: userId } = useAppSelector((state) => state.global.user);

  const [logoutPatientMutation] = profileApi.useLogoutPatientMutation();
  const { data } = encounterConsultApi.useGetEncounterConsultsQuery({ userId });
  const consults = data?.data;

  useEffect(() => {
    dispatch(fetchSubscription());
  }, [dispatch]);

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to="/profile">My Profile</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="1"
        onClick={async () => {
          await logoutPatientMutation(undefined);
          Mixpanel.track('logout', {});
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  return (
    <>
      <NavbarWrapper>
        <Space>
          {!breakpoints.lg && (
            <Button
              type="link"
              onClick={toggleSidebar}
              style={{ padding: '4px 8px' }}
            >
              <NavbarMenuSvg width={24} height={24} fill="currentColor" />
            </Button>
          )}
          <img src={AppIcon} alt="icon" />
        </Space>

        <Space>
          {consults &&
          consults[0] &&
          consults[0]?.status === 'accepted' &&
          !location.pathname.includes('/encounters') ? (
            <div className="continue-consultation">
              <p>
                Hi, {user?.first_name}, You have an ongoing consultation with
                Dr. {consults[0]?.provider?.first_name}
              </p>
              <Button
                type="primary"
                onClick={() =>
                  history.push(`/encounters/consults/${consults[0].id}`)
                }
              >
                Continue consultation
              </Button>
            </div>
          ) : null}

          {plan === 'paygo' ? (
            <Button
              className="upgrade-btn"
              onClick={() => setIsUpgradeModalOpen(true)}
            >
              <UpgradeIcon />
              Upgrade now
            </Button>
          ) : null}

          <Dropdown overlay={menu} trigger={['click']}>
            <MenuLink>
              {user?.first_name && (
                <Space>
                  <span>
                    {user?.first_name} {user?.last_name}
                  </span>
                  <UserAvatar src={user?.image} alt="icon">
                    {user?.first_name?.slice(0, 1)}
                  </UserAvatar>
                </Space>
              )}
            </MenuLink>
          </Dropdown>
        </Space>
      </NavbarWrapper>

      <UpgradePlanModal
        isOpen={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
      />
    </>
  );
};

export default Navbar;
