import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';
import http from '../../utils/api';
import { fetchSingleConsult } from './consults';
import { message } from 'antd';

export interface DiagnosisError {
  message: string;
}

export interface DiagnosisState {
  diagnosis: any;
  diagnoses: Array<any>;
  isDiagnosesLoading: boolean;
  addDiagnosesLoading: boolean;
  diagnosesError: DiagnosisError;
  addDiagnosesError: DiagnosisError;
}

export const initialState: DiagnosisState = {
  diagnosis: null,
  diagnoses: [],
  isDiagnosesLoading: false,
  addDiagnosesLoading: false,
  diagnosesError: { message: '' },
  addDiagnosesError: { message: '' }
};

export const diagnosesSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    fetchDiagnosesLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isDiagnosesLoading = payload;
    },
    fetchDiagnosesSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.diagnoses = payload;
    },
    fetchDiagnosesFailed: (
      state,
      { payload }: PayloadAction<DiagnosisError>
    ) => {
      state.diagnosesError = payload;
    },
    addDiagnosesLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.addDiagnosesLoading = payload;
    },
    addDiagnosesSuccess: (state, { payload }: PayloadAction<Boolean>) => {
      // state.diagnoses = payload;
    },
    addDiagnosesFailed: (state, { payload }: PayloadAction<DiagnosisError>) => {
      state.addDiagnosesError = payload;
    }
  }
});

export const {
  fetchDiagnosesLoading,
  fetchDiagnosesSuccess,
  fetchDiagnosesFailed,
  addDiagnosesLoading,
  addDiagnosesSuccess,
  addDiagnosesFailed
} = diagnosesSlice.actions;
export const diagnosisSelector = (state: { diagnoses: DiagnosisState }) =>
  state.diagnoses;
export default diagnosesSlice.reducer;

/** Actions */

export const fetchAllDiagnoses = (): AppThunk => async (dispatch) => {
  dispatch(fetchDiagnosesLoading(true));
  await http
    .get('/diagnosis')
    .then((res) => {
      const diagnoses = res?.data?.data;
      dispatch(fetchDiagnosesSuccess(diagnoses));
    })
    .catch((err) => {
      const message = { message: err?.response?.data?.message };
      dispatch(fetchDiagnosesFailed(message));
    });
  dispatch(fetchDiagnosesLoading(false));
};

export const addConsultDiagnoses =
  (consultId: any, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(addDiagnosesLoading(true));
    await http
      .post(`/consults/${consultId}/diagnosis`, payload)
      .then((res) => {
        const examinations = res?.data?.data;
        dispatch(addDiagnosesSuccess(examinations));
        message.success('Diagnosis Added to Consult Successfully');
        dispatch(fetchSingleConsult(consultId));
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || 'An error occurred'
        };
        dispatch(addDiagnosesFailed(_message));
        message.error(err?.response?.data?.message || 'An error occurred');
      });
    dispatch(addDiagnosesLoading(false));
  };
