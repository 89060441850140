import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import firebase from 'firebase/app';
import 'firebase/auth/';
import 'firebase/firestore';
import firebase from 'firebase';
import 'firebase/performance';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { ThemeProvider } from 'styled-components';
import theme from './constants/theme';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './registerServiceWorker';
import { init } from 'emailjs-com';
import { PersistGate } from 'redux-persist/integration/react';

// init emailjs with user id
init(process.env.REACT_APP_EMAILJS_USER_ID as string);

// dev firebase instantiation
const firebaseConfig = {
  apiKey: 'AIzaSyD4ga9Zy9OwsD6vN1vCrKCkB_Z7RpYsixc',
  authDomain: 'pneuma-pmp.firebaseapp.com',
  projectId: 'pneuma-pmp',
  storageBucket: 'pneuma-pmp.appspot.com',
  messagingSenderId: '268074968873',
  appId: '1:268074968873:web:75096b009de48fdc06f938',
  measurementId: 'G-9MWSL0JG9M'
};

// production firebase instantiation
const firebaseConfigProd = {
  apiKey: 'AIzaSyCdO_eb9FNeVcYQNuVWw0ttqmvAJ4QrjlQ',
  authDomain: 'pneumacare-prod.firebaseapp.com',
  projectId: 'pneumacare-prod',
  storageBucket: 'pneumacare-prod.appspot.com',
  messagingSenderId: '699260507936',
  appId: '1:699260507936:web:c42a14d187367fd06d2945',
  measurementId: 'G-BG9KJG535C'
};

const appInstance = process.env.REACT_APP_ENVIRONMENT as string;

firebase.initializeApp(
  appInstance === 'development' ? firebaseConfig : firebaseConfigProd
);

export const storage = firebase.storage();

// Initialize Performance Monitoring and get a reference to the service
const perf = firebase.performance();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
