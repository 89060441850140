import { format } from 'date-fns';
import moment from 'moment';
import { COMPLAINTS } from 'screens/Encounters/containers/Start/Complaints';

/**
 * Returns an array containing disabled hours based on if todays date was selected in the `DatePicker`. If `today` was selected, return an array combining both outside work hours and below current hour hours. Else, for non `today` future dates return just outside work hours. Work hours are gotten from the metadata's `pneumapage` object
 * @param isTodaySelected boolean
 * @param selectedDaysHours number[]
 * @returns number[]
 */
export const getDisabledHours = (
  isTodaySelected: boolean,
  selectedDaysHours?: number[]
) => {
  const allHours: number[] = [];
  for (let i = 0; i <= 24; i++) {
    allHours.push(i);
  }

  if (!isTodaySelected) {
    return allHours.filter((hour) =>
      selectedDaysHours?.length ? !selectedDaysHours?.includes(hour) : !hour
    );
  }

  let disabledHours = allHours.filter((hour) =>
    selectedDaysHours?.length ? !selectedDaysHours?.includes(hour) : !hour
  );

  for (let i = 0; i < moment().hour(); i++) {
    if (
      selectedDaysHours?.length
        ? selectedDaysHours?.includes(i)
        : allHours.includes(i)
    ) {
      disabledHours.push(i);
    }
  }
  return disabledHours;
};

/**
 * Returns an array containing disabled minutes. If `isTodaySelected` is true, and `selectedHour` is outside of work hours or -1 (no selection), then return all numbers from 1 to 60. Else only disable minutes less than current time.
 * @param selectedHour number
 * @param isTodaySelected boolean
 * @returns number[]
 */
export const getDisabledMinutes = (
  selectedHour: number,
  isTodaySelected: boolean
) => {
  const minutes = [];
  if (
    isTodaySelected &&
    (selectedHour > 19 || selectedHour < 7 || selectedHour === -1)
  ) {
    for (let i = 0; i < 60; i++) {
      minutes.push(i);
    }
  }

  if (selectedHour === moment().hour()) {
    for (let i = 0; i < moment().minute(); i++) {
      minutes.push(i);
    }
  }
  return minutes;
};

export const returnFirstName = (words: string) => {
  if (words) {
    return words
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .find((data, idx) => idx === 0);
  }
};

export const capitalizeFirstLetter = (string: string) => {
  return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);
};

export async function triggerPushNotification(title: string, message: string) {
  if ('serviceWorker' in navigator) {
    Notification.requestPermission()
      .then(async () => {
        try {
          const register = await navigator.serviceWorker.register(
            `${process.env.PUBLIC_URL}/service-worker.js`,
            {
              scope: '/'
            }
          );

          const subscription = await register.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
              process.env.REACT_APP_PUBLIC_VAPID_KEY!
            )
          });

          await fetch(
            `${process.env.REACT_APP_CONNECT_API_BASE_URL}/subscribe`,
            {
              method: 'POST',
              body: JSON.stringify({
                subscription,
                payload: {
                  title,
                  body: message
                }
              }),
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
        } catch (err) {
          console.log(err);
        }
        console.log('Notifications permission granted');
      })
      .catch((err) => console.log(err));
  } else {
    console.error('Service workers are not supported in this browser');
  }
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns string
 */
export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS';
  }

  return 'unknown';
}

/**
 * Takes in 24-hour start and end hours, and calculates if the current device time is within that range. Currently doesn't account for more granular time periods, e.g time:minute
 * @param startHour
 * @param endHour
 * @returns boolean
 */
export function isSpecificTimeOfDay(startHour: number, endHour: number) {
  const startMinutes = startHour * 60;
  const endMinutes = endHour * 60;

  const device_now = new Date();
  const current_time = device_now.getHours() * 60 + device_now.getMinutes();

  return current_time > startMinutes && current_time < endMinutes;
}

/**
 *  Takes in a symptom id, cross references with existing list & returns the appropriate name. (Eliminates the need for an extra network request)
 * @param symptomId string
 * @returns string - Symptom name
 */
export const getSymptomName = (symptomId: string) => {
  // reduce grouped array of symptoms into just the symptoms
  const symptomArr: {
    id: string;
    name: string;
  }[] = COMPLAINTS.reduce((acc: any, element) => {
    acc.push(...element.symptoms);
    return acc;
  }, []);

  let selectSymptom = symptomArr.find((symptom) => symptom.id === symptomId);

  if (symptomId === 'k4o2il9d1kfp3jl') {
    selectSymptom = {
      id: 'k4o2il9d1kfp3jl',
      name: 'Quick Doctor Consultation'
    };
  }

  return selectSymptom ? selectSymptom.name : '';
};

export const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

interface ConsultCreatedPayload {
  complaintName: string;
  userName: string;
}

/**
 * Sends a consult created notification message to a specified discord channel
 * @param payload ConsultCreatedPayload
 */
export async function sendDiscordMessage(payload: ConsultCreatedPayload) {
  const request = new XMLHttpRequest();
  request.open(
    'POST',
    'https://discord.com/api/webhooks/1236959504743272489/03cBD7w0WozCA1R5iAJQnIAg5c4az_vwM_yQgDd_Eaapje7_9CNYntcTh2_Q3zjYch9c'
  );

  request.setRequestHeader('Content-type', 'application/json');
  const params = {
    username: 'North Webhook',
    content: `
      ####################################
#    New Consult created!          #
Accept request and start consultation at https://providers.pneuma.care
####################################

  
Consult: ${payload.complaintName}
User: ${payload.userName}
Created at: ${format(new Date(), 'MMM do yyyy, h:ma')}

      `
  };

  request.send(JSON.stringify(params));
}
